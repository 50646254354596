/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import ReactImageUploading from "react-images-uploading";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import htmlToDraft from "html-to-draftjs";
import { Button, Textarea } from "@material-tailwind/react";
import { FiTrash } from "react-icons/fi";
import { toast } from "react-toast";
import InputField from "../../../components/input";
import {
  getEventDetailsById,
  getProductDetailsById,
} from "../../../service/api";
import { ContentState, EditorState } from "draft-js";
import { IMAGE_URL } from "config";

const ViewEvent = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [EventDetails, setEventDetails] = useState(null);
  console.log("%c Line:50 🍞 EventDetails", "color:#93c0a4", EventDetails);
  const [productImages, setProductImages] = useState([]);
  const [productDescription, setProductDescription] = useState(
    EditorState.createEmpty()
  );

  const onImageChange = (imageList) => {
    setProductImages(imageList);
  };

  const handleGetEventDetails = () => {
    setLoading(true);
    getEventDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setEventDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetEventDetails();
  }, [id]);

  useEffect(() => {
    if (EventDetails) {
      let image = [IMAGE_URL + EventDetails?.image];
      setProductImages(image);
    }
  }, [EventDetails]);
  console.log("%c Line:98 🧀 EventDetails", "color:#4fff4B", EventDetails);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      <Helmet>
        <title>View Event Details | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              View Event Details
            </h1>
          </div>
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/event">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back
              </Button>
            </Link>
          </div>
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
            <div className="col-span-2">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Event Details
                  </h5>
                </div>
                <div className="p-5">
                  <form>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Event Name <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="name"
                              id="name"
                              disabled={true}
                              value={EventDetails?.name || ""}
                              placeholder="Write the event name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Description <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Textarea
                              value={EventDetails.description}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* IMAGES AND DISCOUNTS */}
            <div className="col-span-1">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Event Image
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    Attach the event images
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    // multiple
                    value={productImages}
                    readOnly={true}
                    maxNumber={5}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                        {imageList && imageList.length > 0 ? (
                          imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image}
                                className="w-30 h-30 object-cover rounded-md"
                                alt="Product"
                              />
                              {/* <div className="absolute top-2 right-2">
                      <button
                        className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                        // onClick={() => onImageRemove(index)}
                
                        {/* <FiTrash className="w-3 h-3 text-black" /> 
                      </button>
                    </div> */}
                            </div>
                          ))
                        ) : (
                          <div className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder p-5">
                            <p className="text-s font-proxima-semibold text-greyText text-center mt-2">
                              No Images found
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewEvent;
