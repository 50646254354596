/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import ReactImageUploading from "react-images-uploading";
import ReactPaginate from "react-paginate";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Dialog,
  DialogBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

/* ICONS */
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { GrNext, GrPrevious } from "react-icons/gr";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* IMPORTED APIs */
import { IMAGE_URL } from "../../../config";
import {
  addTagDetails,
  deleteTagById,
  editTagDetailsById,
  getTagList,
} from "service/api";

/* Validation Schema */
// Validation Schema For Tag Add Form
const validationSchemaForTagAdd = yup.object().shape({
  name: yup.string().required("Tag Name is required."),
});

const Tags = () => {
  // GLOBAL VARIABLES
  const userDetails = useSelector((state) => state?.user);

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [tagList, setTagList] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [tagImages, setTagImages] = useState([]);

  /* MODAL STATES */
  const [addDialog, setAddDialog] = useState(false);
  const [addTagFormLoading, setAddTagFormLoading] = useState(false);

  const [editDialog, setEditDialog] = useState(false);
  const [editTagId, setEditTagId] = useState(null);
  const [editTagFormLoading, setEditTagFormLoading] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [viewDialog, setViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  /* CATEGORY BOX */
  const TagBox = (props) => {
    return (
      <div className="bg-white rounded-lg border border-authBorder p-4 h-[100px] max-h-[400px] flex flex-col">
        <div className="flex items-center justify-between mb-2">
          <h5 className="text-lg font-proxima-bold text-black truncate">
            {props.name}
          </h5>
          <Menu placement="right">
            <MenuHandler>
              <Button className="p-2 shadow-none hover:shadow-none bg-white rounded-full border border-authBorder hover:border-logoYellow transition-all duration-300 ease-in-out">
                <HiOutlineDotsVertical className="w-4 h-4 text-black" />
              </Button>
            </MenuHandler>
            <MenuList className="p-2">
              <MenuItem
                className="text-sm font-proxima-semibold text-black"
                onClick={() => {
                  setEditTagId(props.id);
                  setEditDialog(!editDialog);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                className="text-sm font-proxima-semibold text-black"
                onClick={() => {
                  setViewDetails(props.details);
                  console.log(
                    "%c Line:120 🍌 props.details",
                    "color:#33a5ff",
                    props.details
                  );
                  setViewDialog(!viewDialog);
                }}
              >
                View
              </MenuItem>
              <MenuItem
                className="text-sm font-proxima-semibold text-black"
                onClick={() => {
                  setDeleteId(props.id);
                  setDeleteDialog(!deleteDialog);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
        <div className="flex flex-wrap gap-2 mt-auto">
          {props?.status ? (
            <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-green-500 bg-green-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
              Enabled
            </Button>
          ) : (
            <Button
              className="shadow-none hover:shadow-none px-4 py-2 rounded-md bg-white border border-authBorder hover:bg-green-500 hover:bg-opacity-10 hover:border-green-500 transition-all duration-300 ease-in-out text-black hover:text-green-500 font-proxima-semibold text-xs normal-case leading-none font-medium"
              onClick={() => enableTag(props.details)}
            >
              Enable
            </Button>
          )}
          {!props?.status ? (
            <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-red-500 bg-red-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
              Disabled
            </Button>
          ) : (
            <Button
              className="shadow-none hover:shadow-none px-4 py-2 rounded-md bg-white border border-authBorder hover:bg-red-500 hover:bg-opacity-10 hover:border-red-500 transition-all duration-300 ease-in-out text-black hover:text-red-500 font-proxima-semibold text-xs normal-case leading-none font-medium"
              onClick={() => disableTag(props.details)}
            >
              Disable
            </Button>
          )}
        </div>
      </div>
    );
  };

  // Formik Function For Adding Tag
  const addTagFormik = useFormik({
    initialValues: {
      name: "",
      status: false,
    },
    validationSchema: validationSchemaForTagAdd,
    onSubmit: (values) => {
      console.log("Adding Tag:", values);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("status", values.status ? 1 : 0); // Convert boolean to number

      if (tagImages?.[0]?.file) {
        formData.append("image", tagImages?.[0]?.file);
      }

      for (const entry of formData.entries()) {
        console.log(`${entry[0]}: ${entry[1]}`);
      }

      setAddTagFormLoading(true);
      addTagDetails(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            addTagFormik.handleReset();
            setTagImages([]);
            setAddDialog(false);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setAddTagFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally(() => {
          tagGetFunction();
          setAddTagFormLoading(false);
        });
    },
  });

  // Formik Function For Editing Tag
  const editTagFormik = useFormik({
    initialValues: {
      name: "",
      status: false,
    },
    validationSchema: validationSchemaForTagAdd,
    onSubmit: (values) => {
      console.log("Editing Tag:", values);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("status", values.status ? 1 : 0);

      if (tagImages?.[0]?.file) {
        formData.append("image", tagImages?.[0]?.file);
      }

      for (const entry of formData.entries()) {
        console.log(`${entry[0]}: ${entry[1]}`);
      }

      // setAddTagFormLoading(true);
      editTagDetailsById(editTagId, formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            editTagFormik.handleReset();
            setTagImages([]);
            setAddDialog(false);
            setEditTagId(null);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setAddTagFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally(() => {
          tagGetFunction();
          setAddTagFormLoading(false);
        });
    },
  });

  //Formik Function For Delete Tag
  const deleteTag = (tagId) => {
    deleteTagById(tagId)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        tagGetFunction();
      });
  };

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setTagImages(imageList);
  };

  /* DISABLE CATEGORY FUNCTION */
  const disableTag = (object) => {
    const obj = {
      status: 0,
      name: object?.name || "",
    };

    editTagDetailsById(object?._id, obj)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        tagGetFunction();
      });
  };

  /* ENABLE CATEGORY FUNCTION */
  const enableTag = (object) => {
    const obj = {
      status: 1,
      name: object?.name || "",
    };

    editTagDetailsById(object?._id, obj)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        tagGetFunction();
      });
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    setLoading(true);
    setTagList([]);
    setPage(selectedPage);
  };

  // Functions
  const tagGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      pagination: true,
    };
    if (search) {
      obj.search = search;
    }
    if (selectedStatus) {
      obj.status = selectedStatus?.value;
    }

    setLoading(true);
    getTagList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response);
          setTagList(response?.data?.docs);
          setTotalDocs(response?.data?.totalDocs);
          setTotalPages(response?.data?.totalPages);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tagList && tagList.length > 0) {
      const firstTag = tagList?.find((obj) => obj?._id === editTagId);
      editTagFormik.setValues({
        name: firstTag?.name || "",
        image: firstTag?.image || null,
      });
      if (firstTag?.image) {
        setTagImages([{ data_url: firstTag.image }]);
      }
    }
  }, [tagList]);

  /* USE-EFFECTS */
  useEffect(() => {
    tagGetFunction();
  }, [page, sizePerPage, selectedStatus, search]);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Sectors | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Sectors
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              Maintain existing website sectors.
            </h2>
          </div>

          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search your sector"
                name="tags"
                id="tags"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>

            {/* ADD TAG */}
            <Button
              className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out"
              onClick={() => setAddDialog(!addDialog)}
            >
              Add Sector
            </Button>
          </div>
        </div>

        {tagList?.length === 0 && loading === true && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">
              Please wait we are fetching your Sector...
            </p>
          </div>
        )}
        {tagList?.length === 0 && loading === false && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">
              We were not able to find any Sector.
            </p>
          </div>
        )}
        {console.log("%c Line:412 🍣 tagList", "color:#e41a6a", tagList)}
        {/* TAGS LIST */}
        <div className="mt-5 xl:mt-10 lg:mt-10 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
          {tagList &&
            tagList?.length > 0 &&
            tagList?.map((data) => (
              <div className="col-span-1" key={data._id}>
                <TagBox
                  id={data._id}
                  name={data?.name}
                  status={data.status}
                  details={data}
                />
              </div>
            ))}
        </div>
      </div>

      {tagList?.length > 0 && (
        <ReactPaginate
          className="lists-pagination"
          previousLabel={
            <Button className="list-pagination-btn">
              <GrPrevious color="#323232" size="20" />
            </Button>
          }
          nextLabel={
            <Button className="list-pagination-btn">
              <GrNext color="#323232" size="20" />
            </Button>
          }
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={3}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          activeClassName={"active"}
        />
      )}

      {/* ADD CATEGORY DIALOG */}
      <Dialog
        open={addDialog}
        handler={() => setAddDialog(!addDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <form onSubmit={addTagFormik.handleSubmit}>
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Add Tag
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setAddDialog(!addDialog)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              {/* CATEGORY NAME */}
              <div className="form-group">
                <label className="text-sm font-proxima-semibold text-black">
                  Tag Name <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={addTagFormik.handleChange}
                    value={addTagFormik.values.name}
                    error={
                      addTagFormik.errors.name && addTagFormik.touched.name
                        ? true
                        : false
                    }
                    placeholder="Write the tag name"
                  />
                </div>
              </div>
              <div className="mt-2 grid grid-cols-1 xl:grid-cols-6 gap-3"></div>

              {/* CATEGORY IMAGES */}
              <div className="bg-white rounded-lg border border-authBorder mt-3">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-sm font-proxima-bold text-black">
                    Tag Images
                  </h5>
                  <p className="text-xs font-proxima-regular text-greyText">
                    Attach the tag images for this product
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    value={tagImages}
                    onChange={onImageChange}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        <div className="col-span-3">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                            <div className="mt-2">
                              <p className="text-xs font-proxima-semibold text-greyText text-center">
                                Click here to upload images
                              </p>
                              <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p>
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-3 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end px-5 py-3">
              <Button
                disabled={addTagFormLoading}
                type="submit"
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                {addTagFormLoading ? "Please wait.." : "Save"}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>

      {/* EDIT CATEGORY DIALOG */}
      <Dialog
        open={editDialog}
        handler={() => setEditDialog(!editDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <form onSubmit={editTagFormik.handleSubmit}>
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Edit Tag
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setEditDialog(!editDialog)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              {/* TAG NAME */}
              <div className="form-group">
                <label className="text-sm font-proxima-semibold text-black">
                  Tag Name <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={editTagFormik.handleChange}
                    value={editTagFormik.values.name}
                    error={
                      editTagFormik.errors.name && editTagFormik.touched.name
                        ? true
                        : false
                    }
                    placeholder="Write the tag name"
                  />
                </div>
              </div>
              <div className="mt-2 grid grid-cols-1 xl:grid-cols-6 gap-3"></div>

              {/* TAG IMAGES */}
              <div className="bg-white rounded-lg border border-authBorder mt-3">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-sm font-proxima-bold text-black">
                    Tag Images
                  </h5>
                  <p className="text-xs font-proxima-regular text-greyText">
                    Attach the tag images for this product
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    value={tagImages}
                    onChange={onImageChange}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        <div className="col-span-3">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                            <div className="mt-2">
                              <p className="text-xs font-proxima-semibold text-greyText text-center">
                                Click here to upload images
                              </p>
                              <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p>
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-3 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end px-5 py-3">
              <Button
                disabled={addTagFormLoading}
                type="submit"
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                {addTagFormLoading ? "Please wait.." : "Save "}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>

      {/* DELETE CATEGORY DIALOG */}
      <Dialog
        open={deleteDialog}
        handler={() => setDeleteDialog(!deleteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Delete Tag
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setDeleteDialog(!deleteDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <p className="text-base font-proxima-regular text-black">
              Are you sure you want to delete this tag?
            </p>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button
              onClick={() => {
                deleteTag(deleteId);
                setDeleteDialog(!deleteDialog);
              }}
              className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
            >
              Delete
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* VIEW CATEGORY DIALOG */}
      <Dialog
        open={viewDialog}
        handler={() => setViewDialog(!viewDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              View Tag
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setViewDialog(!viewDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black ">
                {viewDetails && viewDetails?.name}
              </h5>
            </div>
            <div className="mt-1">
              {/* <div className="flex items-center gap-2 mt-2">
                {viewDetails && viewDetails?.status === 1 && (
                  <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-green-500 bg-green-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
                    Enabled
                  </Button>
                )}

                {viewDetails && viewDetails?.status === 0 && (
                  <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-red-500 bg-red-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
                    Disabled
                  </Button>
                )}
              </div> */}
              {viewDetails && viewDetails?.image && (
                <div className="mt-3">
                  <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                    <div className="col-span-3 bg-white rounded-lg border border-authBorder relative">
                      <img
                        className="rounded-lg"
                        alt={viewDetails && viewDetails?.name}
                        src={viewDetails && IMAGE_URL + viewDetails?.image}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Tags;
