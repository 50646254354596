/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* ICONS */
import { IoClose } from "react-icons/io5";

/* SAMPLE DATA */
import Input from "../../../components/input";

import { Link, useParams } from "react-router-dom";

import { toast } from "react-toast";
import {
  getSponsorRequestDetailsById,
  patchSponsorRequestStatusDetailsById,
} from "service/api";
import moment from "moment";
import { IMAGE_URL } from "config";

const SponsorRequestDetails = () => {
  // GLOBAL VARIABLES
  const { id } = useParams();

  // State variables
  const [loading, setLoading] = useState(false);
  const [sponsorRequestDetails, setSponsorRequestDetails] = useState(null);

  const [modalStatus, setModalStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [paymentMode, setPaymentMode] = useState();
  const [payAmount, setPayAmount] = useState();

  const handleGetProductDetails = () => {
    setLoading(true);

    getSponsorRequestDetailsById(id)
      .then((res) => {
        setSponsorRequestDetails(res?.data);
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.message || "Failed to fetch product details"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusChange = () => {
    const obj = {};
    if (status?.value === "approve") {
      obj.status = status?.value;
      if (paymentMode && payAmount) {
        obj.paymentMode = paymentMode;
        obj.amount = payAmount;
      } else if (paymentMode && !payAmount) {
        toast.error("Pay Amount is required");
        return false;
      } else if (!paymentMode && payAmount) {
        toast.error("Payment Mode is required");
        return false;
      } else {
        toast.error("Please add all required fields");
        return false;
      }
    } else if (status?.value === "reject") {
      obj.status = "reject";
    }
    patchSponsorRequestStatusDetailsById(id, obj)
      .then((res) => {
        toast.success(res?.message);
        setModalStatus(false);
        setPayAmount(null);
        setPaymentMode(null);
        setStatus(null);
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          if (e?.response?.data?.message?.details?.[0]?.message !== undefined) {
            toast.error(e?.response?.data?.message?.details?.[0]?.message);
          } else {
            toast.error(e?.response?.data?.message);
          }
        } else {
          toast.error(e?.message);
        }
      });
  };

  // Effect to fetch both SponsorRequest list and product details when id changes
  useEffect(() => {
    if (id) {
      handleGetProductDetails();
    }
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Sponsor details | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        {sponsorRequestDetails === null && loading === true && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-ibm-regular text-greyText tracking-tight text-center">
              Please wait we are fetching sponsor details...
            </p>
          </div>
        )}
        {sponsorRequestDetails === null && loading === false && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-ibm-regular text-greyText tracking-tight text-center">
              We were not able to find sponsor details.
            </p>
          </div>
        )}
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Sponsor details
            </h1>
          </div>

          {/* VIEW INVOICE */}
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link
              to={"/sponsor-request/" + sponsorRequestDetails?.eventId?._id}
            >
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-ibm-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back
              </Button>
            </Link>
            {sponsorRequestDetails?.paymentStatus !== "success" &&
              sponsorRequestDetails?.paymentStatus !== "rejected" && (
                <Button
                  onClick={() => setModalStatus(!modalStatus)}
                  className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-logoYellow hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-ibm-semibold text-authBorder hover:text-black normal-case transition-all duration-300 ease-in-out"
                >
                  Change Status
                </Button>
              )}
          </div>
        </div>

        {/* CUSTOMERS LIST */}
        <div className="mt-5 xl:mt-5 lg:mt-5">
          <div className=" bg-white border border-authBorder rounded-md overflow-y-auto overflow-x-hidden hide-scrollbar">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 h-full">
              <div className="col-span-3 h-full order-2 xl:order-1 lg:order-1">
                <div className="border-r border-authBorder h-full">
                  <div className="p-5 xl:p-5 lg:p-5">
                    <div className="grid grid-cols-12 h-full gap-5 pb-3 ">
                      {/* COMPANY INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3 ">
                        <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2">
                          Company Details
                        </h3>

                        {sponsorRequestDetails?.companyId?.image && (
                          <img
                            src={
                              IMAGE_URL +
                              sponsorRequestDetails?.companyId?.image
                            }
                            alt=""
                            className="w-1/4 mt-2"
                          />
                        )}

                        <p className="text-base font-ibm-bold text-black mt-3">
                          {sponsorRequestDetails?.companyId?.name}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          {sponsorRequestDetails?.companyId?.address?.address}
                        </p>

                        <p className="text-base font-ibm-regular text-greyText">
                          {sponsorRequestDetails?.companyId?.address?.city} ,{" "}
                          {sponsorRequestDetails?.companyId?.address?.state} ,{" "}
                          {sponsorRequestDetails?.companyId?.address?.zipCode} -{" "}
                          {sponsorRequestDetails?.companyId?.address?.country}
                        </p>

                        <p className="text-base font-ibm-regular text-greyText mt-3">
                          Billing Name:{" "}
                          {sponsorRequestDetails?.companyId?.billingName}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          Website:{" "}
                          <a
                            href={sponsorRequestDetails?.companyId?.websiteUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {sponsorRequestDetails?.companyId?.websiteUrl}
                          </a>
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          Sectors:{" "}
                          {sponsorRequestDetails?.companyId?.sectors?.join(
                            ", "
                          )}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText flex">
                          Area Covered By Brand: &nbsp;
                          {sponsorRequestDetails?.companyId?.areaCoveredByBrand?.map(
                            (element, index) => {
                              return <p key={index}> {element}</p>;
                            }
                          )}
                        </p>

                        <p className="text-base font-ibm-bold  mt-3 text-black">
                          Services
                        </p>
                        {sponsorRequestDetails?.companyId?.services?.length >
                        0 ? (
                          sponsorRequestDetails?.companyId?.services.map(
                            (service, index) => (
                              <div key={index} className="mb-4">
                                <p className="text-base font-ibm-bold text-greyText">
                                  {service.name}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText">
                                  {service.description}
                                </p>
                              </div>
                            )
                          )
                        ) : (
                          <p className="text-base font-ibm-regular text-greyText">
                            No services available
                          </p>
                        )}
                      </div>

                      {/* SPONSOR INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3">
                        <div>
                          <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2">
                            Sponsors Details
                          </h3>

                          <p className="text-base font-ibm-regular text-greyText mt-2">
                            Primary Objective:{" "}
                            {sponsorRequestDetails?.primaryObjective}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText mt-2">
                            Specific Requirement For Brand :{" "}
                            {sponsorRequestDetails?.specificRequirementForBrand}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText mt-2">
                            Give Away To Attendees:{" "}
                            {sponsorRequestDetails?.giveAwayToAttendees}
                          </p>

                          <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2 mt-3">
                            Requested Personal Information
                          </h3>
                          <p className="text-base font-ibm-regular text-greyText mt-3">
                            {`${sponsorRequestDetails?.addedBy?.firstName} ${sponsorRequestDetails?.addedBy?.lastName}`}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText">
                            {sponsorRequestDetails?.addedBy?.email}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText">
                            Phone no :{" "}
                            {sponsorRequestDetails?.addedBy?.phoneNumber}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText">
                            WhatsApp no :{" "}
                            {sponsorRequestDetails?.addedBy?.whatsAppPhoneNumber
                              ? sponsorRequestDetails?.addedBy
                                  ?.whatsAppPhoneNumber
                              : "-"}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText">
                            {sponsorRequestDetails?.addedBy?.address?.address}
                          </p>
                          <p className="text-base font-ibm-regular text-greyText">
                            {sponsorRequestDetails?.addedBy?.address?.city} ,{" "}
                            {sponsorRequestDetails?.addedBy?.address?.state} ,{" "}
                            {sponsorRequestDetails?.addedBy?.address?.zipCode} -{" "}
                            {sponsorRequestDetails?.addedBy?.address?.country}
                          </p>

                          <p className="text-base font-ibm-regular text-greyText">
                            Designation:{" "}
                            <span className="text-black">
                              {" "}
                              {sponsorRequestDetails?.addedBy?.designation}
                            </span>
                          </p>
                          <p className="text-base font-ibm-regular text-greyText">
                            Role:{" "}
                            <span className="text-black capitalize">
                              {" "}
                              {sponsorRequestDetails?.addedBy?.role?.name?.toLowerCase()}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1 h-full order-1 xl:order-2 lg:order-2">
                <div className="p-5 xl:p-5 lg:p-5">
                  <div className=" border-b border-authBorder px-0 py-5 xl:px-2 lg:px-2">
                    <h2 className="text-xl font-ibm-bold text-black">
                      Sponsor Level :{" "}
                      {sponsorRequestDetails?.sponsorShipLevel?.name}
                    </h2>
                    <h3 className=" text-sm font-ibm-regular text-greyText  pb-2">
                      {moment(sponsorRequestDetails?.createdAt).format(
                        "MMM DD, YYYY HH:MM A"
                      )}
                    </h3>
                    <span
                      class={`${
                        sponsorRequestDetails?.paymentStatus === "success"
                          ? "bg-green-100  text-green-800"
                          : "bg-orange-100  text-orange-800"
                      } text-sm font-medium me-2 px-2.5 py-2 rounded capitalize`}
                    >
                      {sponsorRequestDetails?.paymentStatus}
                    </span>
                  </div>

                  <div className="mt-3">
                    <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2 mt-5">
                      Event Details
                    </h3>
                    <p className="text-base font-ibm-regular text-greyText mt-2">
                      Event Name: {sponsorRequestDetails?.eventId?.name}
                    </p>
                    <p className="text-base font-ibm-regular text-greyText mt-2">
                      Date: {sponsorRequestDetails?.eventId?.eventDate}
                    </p>
                    <p className="text-base font-ibm-regular text-greyText mt-2">
                      Time: {sponsorRequestDetails?.eventId?.eventTime}
                    </p>
                    <p className="text-base font-ibm-regular text-greyText mt-2">
                      Venue: {sponsorRequestDetails?.eventId?.eventVenue}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <Dialog
        open={modalStatus}
        handler={() => setModalStatus(!modalStatus)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-ibm-bold text-black tracking-tight">
              Change Sponsorship Status
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setModalStatus(!modalStatus)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <Select
              options={[
                { label: "Approve", value: "approve" },
                { label: "Reject", value: "reject" },
              ]}
              placeholder="Change Status"
              classNamePrefix="dropdown"
              name="defaultAddress"
              id="defaultAddress"
              value={status}
              onChange={(e) => {
                setStatus(e);
              }}
            />
            {status?.value === "approve" && (
              <div className="flex gap-4 mt-3">
                <Input
                  type="text"
                  placeholder="Payment Mode"
                  name="paymentMode"
                  id="paymentMode"
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e?.target?.value)}
                />
                <Input
                  type="text"
                  placeholder="Payment Amount"
                  name="paymentAmount"
                  id="paymentAmount"
                  value={payAmount}
                  onChange={(e) => setPayAmount(e?.target?.value)}
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button
              onClick={() => {
                handleStatusChange();
              }}
              className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
            >
              Change
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};
export default SponsorRequestDetails;
