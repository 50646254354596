import { Link } from "react-router-dom";

/* SAMPLE DATA */
import navMenu from "../../data/navigation";

/* ASSETS */
import Logo from "../../assets/images/logo.png";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-screen w-sidebar bg-[#141518] transition-all duration-300 ease-linear">
      {/* HEADER */}
      <div className="h-auto border-b border-white border-opacity-20 px-5 py-3 flex items-start text-start">
        <img
          src={Logo}
          className="w-auto h-16 object-contain text-start "
          alt="LIBF"
        />
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[90vh] flex flex-col justify-between">
        <div className="p-5">
          <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
            MANAGE REGISTRATIONS
          </h5>
          {navMenu.map((nav) => (
            <Link
              to={nav.link}
              key={nav.id}
              className="w-full group flex items-center gap-3 mt-7"
            >
              <nav.icon
                className={`w-5 h-5 text-sidebarText ${pathname === nav.link &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-sm font-ibm-semibold text-sidebarText ${pathname ===
                    nav.link &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  {nav.name}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
