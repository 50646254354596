/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Select from "react-select";

import { State } from "country-state-city";

import { useFormik } from "formik";
import * as yup from "yup";


/* MATERIAL TAILWIND COMPONENTS */
import {
    Button
} from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */
import InputField from "../input";

/* ICONS */
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";

/* ADD ADDRESS DIALOG */
const addressValidation = yup.object().shape({
    // addressLine1: yup.string()
    //     .min(2, "Too short")
    //     .required("Address line 1 is required"),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    taluka: yup.string(),
    district: yup.string(),
    type: yup.string(),
    zipCode: yup.string(),
    zone: yup.object(),
    region: yup.object(),
    country: yup.string().required(),
    defaultAddress: yup.object()
});

const AddAddressFormComponent = (props) => {


    /* STATE VARIABLES */
    const regionsList = props?.regionsList;
    const zonesList = props?.zonesList;


    /* ADD NEW ADDRESS FORM HANDLER */
    const newAddressInfo = useFormik({
        initialValues: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            taluka: "",
            district: "",
            zipCode: "",
            type: "",
            region: "",
            zone: "",
            country: "India",
            defaultAddress: { label: "No", value: false }
        },
        validationSchema: addressValidation,
        onSubmit: (values) => {
            let tempArray = []
            if (props?.address) {
                tempArray = props?.address
            }

            const obj = {}
            if (values.addressLine1) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].addressLine1 = values?.addressLine1
                }
                obj.addressLine1 = values?.addressLine1
            }
            if (values.addressLine2) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].addressLine2 = values?.addressLine2
                }
                obj.addressLine2 = values?.addressLine2
            }
            if (values.state) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].state = values?.state?.value
                }
                obj.state = values?.state?.value
            }
            if (values.city) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].city = values?.city
                }
                obj.city = values?.city
            }
            if (values.taluka) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].taluka = values?.taluka
                }
                obj.taluka = values?.taluka
            }
            if (values.country) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].country = values?.country
                }
                obj.country = values?.country
            }

            if (values.zone) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].zone = values?.zone?.value
                }
                obj.zone = values?.zone?.value
            }
            if (values.region) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].region = values?.region?.value
                }
                obj.region = values?.region?.value
            }

            if (values.zipCode) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].zipCode = values?.zipCode
                }
                obj.zipCode = values?.zipCode
            }
            if (values.district) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].district = values?.district
                }
                obj.district = values?.district
            }
            if (values.type) {
                if (props?.selectedAddress?.addressDetails) {
                    tempArray[props?.selectedAddress?.id].type = values?.type
                }
                obj.type = values?.type
            }
            obj.defaultAddress = values?.defaultAddress?.value
            if (props?.selectedAddress?.addressDetails) {
                tempArray[props?.selectedAddress?.id].defaultAddress = values?.defaultAddress?.value
            }


            console.log("ID", props?.selectedAddress?.id)

            if (props?.selectedAddress?.addressDetails) {
                console.log("propsashkajfhkjahs", tempArray)
            } else {
                tempArray.push(obj)
            }

            // console.log("obj", obj)
            newAddressInfo.handleReset();
            props?.setAddressList(tempArray)
            props?.setAddressDialog(false)
        },
    });

    console.log("newAddressInfo", newAddressInfo.errors)
    console.log("newAddressInfo.values", newAddressInfo.values)
    /* USE EFFECT */
    // useEffect(() => {
    //     let tempArr = [];
    //     State.getStatesOfCountry("IN").forEach((data) => {
    //         tempArr.push({ label: data.name, value: data.name });
    //     });
    //     setStates(tempArr);
    // }, []);

    useEffect(() => {
        if (props?.selectedAddress) {
            newAddressInfo.setFieldValue("addressLine1", props?.selectedAddress?.addressDetails?.addressLine1)
            newAddressInfo.setFieldValue("addressLine2", props?.selectedAddress?.addressDetails?.addressLine2)
            newAddressInfo.setFieldValue("city", props?.selectedAddress?.addressDetails?.city)
            newAddressInfo.setFieldValue("state", props?.selectedAddress?.addressDetails?.state)
            newAddressInfo.setFieldValue("taluka", props?.selectedAddress?.addressDetails?.taluka)
            newAddressInfo.setFieldValue("district", props?.selectedAddress?.addressDetails?.district)
            newAddressInfo.setFieldValue("zipCode", props?.selectedAddress?.addressDetails?.zipCode)
            newAddressInfo.setFieldValue("type", props?.selectedAddress?.addressDetails?.type)
            newAddressInfo.setFieldValue("region", regionsList?.find((el) => el?.value === props?.selectedAddress?.addressDetails?.region))
            newAddressInfo.setFieldValue("zone", zonesList?.find((el) => el?.value === props?.selectedAddress?.addressDetails?.zone))
            newAddressInfo.setFieldValue("country", props?.selectedAddress?.addressDetails?.country)
            newAddressInfo.setFieldValue("defaultAddress", props?.selectedAddress?.addressDetails?.defaultAddress ? { label: "Yes", value: true } : { label: "No", value: false })
        }
    }, [props])


    return <form onSubmit={newAddressInfo.handleSubmit}>
        <div className="grid grid-cols-2 gap-3 pb-5">


            {/* TYPE */}
            <div className="col-span-2 lg:col-span-1">
                <InputField
                    type="text"
                    name="type"
                    id="type"
                    placeholder="Enter Type"
                    value={newAddressInfo.values.type}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.type &&
                        newAddressInfo.touched.type
                    }
                />
            </div>

            {/* DEFAULT ADDRESS */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <div className="relative">
                    <Select
                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                        placeholder="Default Address"
                        className={`${newAddressInfo.errors.defaultAddress && "error"}`}
                        classNamePrefix="dropdown"
                        name="defaultAddress"
                        id="defaultAddress"
                        value={newAddressInfo.values.defaultAddress}
                        onChange={(e) => {
                            newAddressInfo.setFieldValue("defaultAddress", e);
                        }}
                    />

                    {newAddressInfo.errors.defaultAddress &&
                        newAddressInfo.touched.defaultAddress && (
                            <div className="absolute top-3 right-8">
                                <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                            </div>
                        )}

                    {newAddressInfo.values.defaultAddress !== "" && (
                        <div className="absolute top-3 right-8">
                            <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                        </div>
                    )}
                </div>
            </div>


            {/* ADDRESS LINE 1 */}
            <div className="col-span-2">
                <InputField
                    type="text"
                    name="addressLine1"
                    id="addressLine1"
                    placeholder="Address Line 1"
                    value={newAddressInfo.values.addressLine1}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.addressLine1 &&
                        newAddressInfo.touched.addressLine1
                    }
                />
            </div>

            {/* ADDRESS LINE 2 */}
            <div className="col-span-2">
                <InputField
                    type="text"
                    name="addressLine2"
                    id="addressLine2"
                    placeholder="Address Line 2 (optional)"
                    value={newAddressInfo.values.addressLine2}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.addressLine2 &&
                        newAddressInfo.touched.addressLine2
                    }
                />
            </div>

            {/* CITY */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <InputField
                    type="text"
                    name="city"
                    id="city"
                    placeholder="City"
                    value={newAddressInfo.values.city}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.city && newAddressInfo.touched.city
                    }
                />
            </div>

            {/* STATE */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                {/* <div className="relative">
                    <Select
                        options={states}
                        placeholder="State"
                        className={`${newAddressInfo.errors.state && "error"}`}
                        classNamePrefix="dropdown"
                        name="state"
                        id="state"
                        value={newAddressInfo.values.state}
                        onChange={(e) => {
                            newAddressInfo.setFieldValue("state", e);
                        }}
                    />

                    {newAddressInfo.errors.state &&
                        newAddressInfo.touched.state && (
                            <div className="absolute top-3 right-8">
                                <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                            </div>
                        )}

                    {newAddressInfo.values.state !== "" && (
                        <div className="absolute top-3 right-8">
                            <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                        </div>
                    )}
                </div> */}
                <InputField
                    type="text"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={newAddressInfo.values.state}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.state && newAddressInfo.touched.state
                    }
                />
            </div>

            {/* TALUKA */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <InputField
                    type="text"
                    name="taluka"
                    id="taluka"
                    placeholder="Enter your Taluka"
                    value={newAddressInfo.values.taluka}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.taluka &&
                        newAddressInfo.touched.taluka
                    }
                />
            </div>

            {/* DISTRICT */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <InputField
                    type="text"
                    name="district"
                    id="district"
                    placeholder="Enter your District"
                    value={newAddressInfo.values.district}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.district &&
                        newAddressInfo.touched.district
                    }
                />
            </div>

            {/* ZIPCODE */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <InputField
                    type="number"
                    name="zipCode"
                    id="zipCode"
                    placeholder="Zipcode"
                    value={newAddressInfo.values.zipCode}
                    onChange={newAddressInfo.handleChange}
                    error={
                        newAddressInfo.errors.zipCode &&
                        newAddressInfo.touched.zipCode
                    }
                />
            </div>

            {/* COUNTRY */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <InputField
                    type="text"
                    name="country"
                    id="country"
                    placeholder="Country"
                    value={newAddressInfo.values.country}
                    onChange={newAddressInfo.handleChange}
                    errorHandler={
                        newAddressInfo.errors.country &&
                        newAddressInfo.touched.country
                    }
                />
            </div>

            {/* REGION */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <div className="relative">
                    <Select
                        options={regionsList?.filter((el) => el?.zoneId === newAddressInfo.values.zone?.value)}
                        placeholder="Select Region..."
                        classNamePrefix="dropdown"
                        name="region"
                        id="region"
                        value={newAddressInfo.values.region}
                        onChange={(e) => {
                            newAddressInfo.setFieldValue("region", e);
                        }}
                    />
                </div>
            </div>

            {/* ZONE */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <div className="relative">
                    <Select
                        options={zonesList}
                        placeholder="Select Zone..."
                        classNamePrefix="dropdown"
                        name="zone"
                        id="zone"
                        value={newAddressInfo.values.zone}
                        onChange={(e) => {
                            newAddressInfo.setFieldValue("zone", e);
                        }}
                    />
                </div>
            </div>
        </div>

        <div className="border-t border-greyBorder py-2 flex items-center justify-end">
            <Button type="submit" label="Submit details" >Submit Details</Button>
        </div>
    </form>

}

export default AddAddressFormComponent;