/* eslint-disable no-unused-vars */
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
/* REACT ICONS */

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";
import { addCompany } from "service/api";

/* Validation Schema */
// Validation Schema For PromoCode Add Form
const validationSchema = yup.object({
  name: yup.string().required("Product name is required"),
});

const AddCompany = () => {
  const { id } = useParams();
  // GLOBAL VARIABLES
  const userDetails = useSelector((state) => state?.user);

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
 




  const addCompanyFormik = useFormik({
    initialValues: {
      name: "",
     
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("name", values.name);
     
      setLoading(true);
      addCompany(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            addCompanyFormik.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message || "An error occurred");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
   
  }, []);
  console.log(
    "%c Line:367 🥚 addCompanyFormik",
    "color:#3f7cff",
    addCompanyFormik
  );

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Add Comapany | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        <form onSubmit={addCompanyFormik.handleSubmit}>
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Add Company
              </h1>
              {/* <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Fill in the following details to add the product in the inventory
              </h2> */}
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/company">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back 
                </Button>
              </Link>
              <Button
                type="submit"
                disabled={loading}
                className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out"
              >
                {loading ? "Please wait..." : "Save"}
              </Button>
            </div>
          </div>

          {/* FORM */}

          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Comapany Details
                    </h5>
                    {/* <p className="text-sm font-proxima-regular text-greyText">
                      Fill in these product specific details
                    </p> */}
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                        {/* NAME */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Company Name{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                onChange={addCompanyFormik.handleChange}
                                value={addCompanyFormik.values.name}
                                error={
                                  addCompanyFormik.errors.name &&
                                  addCompanyFormik.touched.name
                                    ? true
                                    : false
                                }
                                placeholder="Write the comapany name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* IMAGES*/}
              {/* <div className="bg-white rounded-lg border border-authBorder mt-3">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-sm font-proxima-bold text-black">
                    Images
                  </h5>
                  <p className="text-xs font-proxima-regular text-greyText">
                    Attach the Varient images for this product
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    value={productImages}
                    onChange={onImageChange}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        <div className="col-span-3">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                            <div className="mt-2">
                              <p className="text-xs font-proxima-semibold text-greyText text-center">
                                Click here to upload images
                              </p>
                              <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p>
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-3 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AddCompany;
