/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { BiEdit } from "react-icons/bi";
import { FiTrash } from "react-icons/fi";
import { IoBan, IoClose, IoEyeSharp } from "react-icons/io5";

/* DATA */

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import { IMAGE_URL } from "../../../config";
import { deleteEvent, getEventsList } from "../../../service/api";
import moment from "moment";

const Events = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(30);

  const [EventDetails, setEventDetails] = useState(null);
  console.log("%c Line:50 🍞 EventDetails", "color:#93c0a4", EventDetails);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("");

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getEventListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getEventListFunction();
  };

  const getEventListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      //  pagination: true,
    };
    if (search) {
      params.search = search;
    }
    // if (category) {
    //   params.category = category.value;
    // }
    getEventsList(params)
      .then((res) => {
        console.log("%c Line:103 🥚 res", "color:#2eafb0", res);
        setEventDetails(res?.data);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteEvent(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getEventListFunction();
      });
  };

  /* TABLE COLUMNS */
  const columns = [
    {
      name: "Name",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-ibm-semibold text-black">{row.name}</span>
      ),
    },

    {
      name: "Description",
      grow: 3,
      selector: (row) => (
        <div className="pr-3 !whitespace-break-spaces">
          <p className="text-sm font-ibm-regular text-greyText leading-relaxed">
            {row.description}
          </p>
        </div>
      ),
    },
    {
      name: "Registrations",
      selector: (row) => (
        <Link to={`/rsvp-list/${row?._id}`}>
          <Button className="shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-70 transition-all duration-300 ease-in-out rounded-md px-4 py-2 capitalize text-sm font-ibm-semibold text-white">
            View list
          </Button>
        </Link>
      ),
    },
    {
      name: "Sponsor Requests",
      grow: 1,
      selector: (row) => (
        <Link to={`/sponsor-request/${row?._id}`}>
          <Button className="shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-70 transition-all duration-300 ease-in-out rounded-md px-4 py-2 capitalize text-sm font-ibm-semibold text-white">
            View List
          </Button>
        </Link>
      ),
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </p>
        </>
      ),
    },
    {
      name: "Actions",
      grow: 1,
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-ibm-semibold">
            <Link to={"/event/view-event/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip content="Edit" className="text-sm font-ibm-semibold">
            <Link to={"/event/edit-event/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <BiEdit className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip content="Remove" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-6 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getEventListFunction();
  }, [page, sizePerPage, search, selectedStatus]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no events to display.
        </p>
      </div>
    );
  };

  // const handleCategoryChange = (option) => {
  //   setSelectedCategory(option); // Update state with selected options
  // };
  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Event | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Events
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText tracking-tight">
              Manage the Events currently present on the website
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search"
                name="searchInv"
                id="searchInv"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            {/* ADD PRODUCT */}
            <Link to="/event/add-event">
              <Button className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-md py-3 px-8 text-sm font-ibm-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                Add Event
              </Button>
            </Link>
          </div>
        </div>

        {/* Delete modal */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this Event?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* INVENTORY TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={Array.isArray(EventDetails) ? EventDetails : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>
    </section>
  );
};

export default Events;
