/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* SAMPLE DATA */

import { Link, useParams } from "react-router-dom";
import Input from "components/input";
import { getCompanyDetailsById } from "service/api";
import { toast } from "react-toast";

const CompanyDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  console.log("%c Line:47 🌽 companyList", "color:#42b983", companyList);

  const handleGetCompanyDetails = () => {
    setLoading(true);
    getCompanyDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setCompanyList(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetCompanyDetails();
  }, [id]);

  useEffect(() => {
    if (companyList) {
      const companyDetails = {
        Id: companyList.companyId,
        name: companyList.name || "",
        areaCoveredByBrand: companyList.areaCoveredByBrand || "",
        billingName: companyList.billingName || "",
        sectors: companyList.sectors || "",
        createdAt: companyList.createdAt || "",
        isSponsor: companyList.isSponsor || "",
        isActive: companyList.isActive || "",
        services:
          companyList.services?.map((service) => service.name).join(", ") || "",
      };

      console.log("Company Details:", companyDetails);
    }
  }, [companyList]);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Comapny Details | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              View Comapny
            </h1>
          </div>
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/company">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back
              </Button>
            </Link>
          </div>
        </div>
        <div className="mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
            <div className="col-span-2">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Company Details
                  </h5>
                </div>
                <div className="p-5">
                  <form>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Company ID <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="Id"
                              id="Id"
                              isDisabled={true}
                              value={companyList?._id || ""}
                              // placeholder="Write the product name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Company Name <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              isDisabled={true}
                              value={companyList?.name || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Area Cover By Brand{" "}
                            <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="areaCoveredByBrand"
                              id="areaCoveredByBrand"
                              isDisabled={true}
                              value={companyList?.areaCoveredByBrand || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Billing Name <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="billingName"
                              id="billingName"
                              isDisabled={true}
                              value={companyList?.billingName || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Sector Name <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="sectors"
                              id="sectors"
                              isDisabled={true}
                              value={companyList?.sectors || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Services Name <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="services"
                              id="services"
                              isDisabled={true}
                              value={
                                companyList?.services
                                  ?.map((service) => service.name)
                                  .join(", ") || ""
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Create Time <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="sectors"
                              id="sectors"
                              isDisabled={true}
                              value={companyList?.createdAt || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Is Active <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="isActive"
                              id="isActive"
                              isDisabled={true}
                              value={companyList?.isActive || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Is Sponsar <span className="text-red-500"></span>
                          </label>
                          <div className="mt-1">
                            <Input
                              type="text"
                              name="isSponsor"
                              id="isSponsor"
                              isDisabled={true}
                              value={companyList?.isSponsor || ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* IMAGES AND DISCOUNTS */}
            <div className="col-span-1">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Address
                  </h5>
                </div>
                <div className="p-5">
                  <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Address <span className="text-red-500"></span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="address"
                          id="address"
                          isDisabled={true}
                          value={`${companyList?.address?.address ||
                            ""}, ${companyList?.address?.city ||
                            ""}, ${companyList?.address?.state ||
                            ""} ${companyList?.address?.zipCode ||
                            ""}, ${companyList?.address?.country || ""}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Payment Details
                  </h5>
                </div>
                <div className="p-5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CompanyDetails;
