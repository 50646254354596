import actions from "../actions/userAction";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  productId: null,
  returnUrl: null,
  redirectUrlParams: null
};
const {
  SET_USER,
  SET_TOKEN,
  SET_LOGGED_IN,
  SET_LOADING,
  SET_PRODUCT_ID,
  SET_RETURN_URL,
  SET_REDIRECT_URL
} = actions;
const userReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: data,
      };
    case SET_PRODUCT_ID:
      return {
        ...state,
        productId: data,
      };
    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: data
      }
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrlParams: data
      }
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default userReducer;
