/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import DataTable from "react-data-table-component";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Card, CardBody } from "@material-tailwind/react";

/* ICONS */

/* SAMPLE DATA */

import { Link, useParams } from "react-router-dom";

import { toast } from "react-toast";
import { getRsvpDetails, getRsvpDetailsById } from "service/api";
import moment from "moment";
import { IMAGE_URL } from "config";

const RsvpDetails = () => {
  // GLOBAL VARIABLES
  const { id } = useParams();

  // Fetching eventId from local storage
  const eventId = localStorage.getItem("eventId");

  // State variables
  const [loading, setLoading] = useState(false);
  const [rsvpDetails, setRsvpDetails] = useState(null);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [rsvpList, setRsvpList] = useState([]);
  const [search, setSearch] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);

  console.log("%c Line:37 🥛 rsvpList", "color:#b03734", rsvpList);
  console.log("%c Line:28 🍐 rsvpDetails", "color:#2eafb0", rsvpDetails);

  // Function to fetch RSVP list
  const getRsvpListFunction = () => {
    setLoading(true);

    const params = {
      page,
      sizePerPage,
      pagination: true,
      ...(search && { search }),
    };

    getRsvpDetails(eventId, params)
      .then((res) => {
        console.log("%c Line:74 🌰 res", "color:#93c0a4", res);
        setRsvpList(res?.data?.docs || []);
        setTotalDocs(res?.data?.totalDocs || 0);
        console.log(
          "%c Line:71 🍣 res?.docs",
          "color:#ea7e5c",
          res?.data?.docs
        );
      })
      .catch((e) => {
        console.log("Error fetching RSVP list:", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to fetch product details
  //   const handleGetProductDetails = () => {
  //     setLoading(true);

  //     getRsvpDetailsById(id)
  //       .then((res) => {
  //         toast.success(res?.message);
  //         setRsvpDetails(res?.data || null);
  //       })
  //       .catch((e) => {
  //         toast.error(e?.response?.data?.message || 'Failed to fetch product details');
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  const handleGetProductDetails = () => {
    setLoading(true);

    getRsvpDetailsById(id)
      .then((res) => {
        const eventId = res.data.eventId; // Assuming eventId is part of the response data

        // Store the eventId in local storage
        localStorage.setItem("eventId", eventId);

        toast.success(res?.message);
        setRsvpDetails(res?.data);

        console.log("Event ID stored in local storage:", eventId);
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.message || "Failed to fetch product details"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Effect to fetch RSVP list when eventId changes
  useEffect(() => {
    if (eventId) {
      getRsvpListFunction();
    }
  }, [eventId]);

  // Effect to fetch both RSVP list and product details when id changes
  useEffect(() => {
    if (id) {
      getRsvpListFunction();
      handleGetProductDetails();
    }
  }, [id]);

  let total = 0;

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Rsvp Details | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        {rsvpDetails === null && loading === true && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-ibm-regular text-greyText tracking-tight text-center">
              Please wait we are fetching rsvp details...
            </p>
          </div>
        )}
        {rsvpDetails === null && loading === false && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-ibm-regular text-greyText tracking-tight text-center">
              We were not able to find rsvp details.
            </p>
          </div>
        )}
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-ibm-bold text-black tracking-tight">
              RSVP Details
            </h1>
          </div>

          {/* VIEW INVOICE */}
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/event">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-ibm-regular text-black normal-case transition-all duration-300 ease-in-out">
                Back to registrations list
              </Button>
            </Link>
          </div>
        </div>

        {/* CUSTOMERS LIST */}
        <div className="mt-3">
          <div className="grid grid-cols-3 gap-5">
            {/* PERSONAL INFORMATION */}
            <div className="col-span-1 h-fit sticky top-5">
              <Card className="bg-white shadow-none border border-authBorder rounded-md">
                <CardBody className="p-5">
                  <h5 className="text-lg font-ibm-bold text-black">
                    Personal Information
                  </h5>
                  <div className="py-5 border-b border-authBorder">
                    <img
                      src={`${IMAGE_URL}${rsvpDetails?.personalDetails?.profileImage}`}
                      className="w-14 h-14 rounded-full shadow-md object-cover"
                      alt="Profile"
                    />
                    <p className="mt-3 text-base font-ibm-semibold text-black">
                      {rsvpDetails?.personalDetails?.firstName}{" "}
                      {rsvpDetails?.personalDetails?.lastName}
                    </p>
                    <a
                      href={`mailto:${rsvpDetails?.personalDetails?.email}`}
                      className="block text-base font-ibm-regular text-greyText hover:text-logoYellow mt-1"
                    >
                      {rsvpDetails?.personalDetails?.email}
                    </a>
                    <a
                      href={`tel:${rsvpDetails?.personalDetails?.phoneNumber}`}
                      className="block text-base font-ibm-regular text-greyText hover:text-logoYellow mt-1"
                    >
                      {rsvpDetails?.personalDetails?.phoneNumber}
                    </a>
                    <p className="text-base font-ibm-regular text-greyText mt-1">
                      {rsvpDetails?.personalDetails?.address?.address}
                    </p>
                    <p className="text-base font-ibm-regular text-greyText mt-1">
                      {rsvpDetails?.personalDetails?.address?.city},{" "}
                      {rsvpDetails?.personalDetails?.address?.state},{" "}
                      {rsvpDetails?.personalDetails?.address?.zipCode}
                    </p>
                    <p className="text-base font-ibm-regular text-greyText mt-1">
                      {rsvpDetails?.personalDetails?.address?.country}
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-sm font-ibm-regular text-greyText">
                      Educational Qualification
                    </p>
                    <p className="mt-1 text-base font-ibm-regular text-black">
                      {rsvpDetails?.personalDetails?.educationalQualification}
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-sm font-ibm-regular text-greyText">
                      Designation
                    </p>
                    <p className="mt-1 text-base font-ibm-regular text-black">
                      {rsvpDetails?.personalDetails?.designation}
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-sm font-ibm-regular text-greyText">
                      Do they belong to Lohana
                    </p>
                    <p className="mt-1 text-base font-ibm-regular text-black">
                      {rsvpDetails?.personalDetails?.belongToLohana
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>

            {/* COMPANY INFORMATION */}
            <div className="col-span-2">
              <Card className="bg-white shadow-none border border-authBorder rounded-md">
                <CardBody className="p-5">
                  <h5 className="text-lg font-ibm-bold text-black">
                    Company Information
                  </h5>
                  <div className="grid grid-cols-2 mt-5">
                    <div className="col-span-1 pr-5 border-r border-authBorder">
                      <div className="pb-3 border-b border-authBorder">
                        <img
                          src={`${IMAGE_URL}${rsvpDetails?.companyDetails?.image}`}
                          className="w-14 h-14 rounded-full shadow-md"
                          alt="Profile"
                        />
                        <p className="mt-3 text-base font-ibm-semibold text-black">
                          {rsvpDetails?.companyDetails?.name}
                        </p>
                        <p className="mt-1 text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.companyDetails?.description}
                        </p>
                        <a
                          href={rsvpDetails?.companyDetails?.websiteUrl}
                          className="mt-1 text-base font-ibm-regular text-blue-400 underline"
                        >
                          {rsvpDetails?.companyDetails?.websiteUrl}
                        </a>
                        <p className="text-base font-ibm-regular text-greyText mt-1">
                          {rsvpDetails?.companyDetails?.address?.address},{" "}
                          {rsvpDetails?.companyDetails?.address?.city},{" "}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText mt-1">
                          {rsvpDetails?.companyDetails?.address?.state},{" "}
                          {rsvpDetails?.companyDetails?.address?.country}
                        </p>
                      </div>
                      <div className="pt-3">
                        <p className="text-sm font-ibm-regular text-greyText">
                          Do they wish to become a sponsor
                        </p>
                        <p className="mt-1 text-base font-ibm-regular text-black">
                          {rsvpDetails?.companyDetails?.isSponsor
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 pl-5">
                      <div className="mt-0">
                        <p className="text-sm font-ibm-regular text-greyText">
                          Sectors
                        </p>
                        <div className="mt-2 flex items-center flex-wrap gap-1">
                          {rsvpDetails?.companyDetails?.sectors?.map(
                            (data, index) => (
                              <div
                                key={index}
                                className="bg-logoYellow bg-opacity-5 border border-logoYellow border-opacity-10 rounded-md px-3 py-1.5"
                              >
                                <p className="text-smxs font-ibm-regular text-logoYellow">
                                  {data}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <p className="text-sm font-ibm-regular text-greyText">
                          Areas covered by brand
                        </p>
                        <div className="mt-2 flex items-center flex-wrap gap-1">
                          {rsvpDetails?.companyDetails?.areaCoveredByBrand?.map(
                            (data, index) => (
                              <div
                                key={index}
                                className="bg-logoYellow bg-opacity-5 border border-logoYellow border-opacity-10 rounded-md px-3 py-1.5"
                              >
                                <p className="text-smxs font-ibm-regular text-logoYellow">
                                  {data}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <p className="text-sm font-ibm-regular text-greyText">
                          Areas wishing to explore by brand
                        </p>
                        <div className="mt-2 flex items-center flex-wrap gap-1">
                          {rsvpDetails?.companyDetails?.areaToExploreByBrand?.map(
                            (data, index) => (
                              <div
                                key={index}
                                className="bg-logoYellow bg-opacity-5 border border-logoYellow border-opacity-10 rounded-md px-3 py-1.5"
                              >
                                <p className="text-smxs font-ibm-regular text-logoYellow">
                                  {data}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5">
                <div className="grid grid-cols-2 gap-5">
                  {/* PAYMENT INFORMATION */}
                  <div className="col-span-1">
                    <Card className="bg-white shadow-none border border-authBorder rounded-md">
                      <CardBody className="p-5">
                        <h5 className="text-lg font-ibm-bold text-black">
                          Payment Information
                        </h5>

                        <div className="py-5 border-b border-authBorder">
                          <div className="flex items-center justify-between">
                            <p className="text-base font-ibm-regular text-black">
                              Payment status
                            </p>
                            {rsvpDetails?.paymentStatus === "success" ? (
                              <p className="text-base font-ibm-semibold text-green-500">
                                SUCCESSFUL
                              </p>
                            ) : (
                              <p className="text-base font-ibm-semibold text-green-500">
                                FAILED
                              </p>
                            )}
                          </div>
                        </div>

                        {rsvpDetails?.paymentSummary?.map((data, index) => (
                          <div
                            key={index}
                            className="pt-4 pb-2 border-b border-authBorder"
                          >
                            <div className="flex items-center justify-between">
                              <div className="p-0">
                                <h5 className="text-base font-ibm-regular text-black">
                                  {data?.type === "DELEGATE"
                                    ? "Delegate Fees"
                                    : "Taxes and Fees"}
                                </h5>
                                <p className="mt-1 text-sm font-ibm-regular text-greyText">
                                  {data?.type === "DELEGATE"
                                    ? `${data?.quantity} * ${data?.price}`
                                    : "18% GST"}
                                </p>
                              </div>
                              <p className="text-base font-ibm-semibold text-black">
                                ₹{data?.quantity * data?.price}
                              </p>
                            </div>
                          </div>
                        ))}

                        <div className="pt-4">
                          <div className="flex items-center justify-between">
                            <p className="text-base font-ibm-semibold text-black">
                              Total amount
                            </p>
                            <p className="text-base font-ibm-semibold text-black">
                              ₹{rsvpDetails?.amount}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  {/* QR CODE */}
                  <div className="col-span-1">
                    <Card className="bg-white shadow-none border border-authBorder rounded-md">
                      <CardBody className="p-5">
                        <h5 className="text-lg font-ibm-bold text-black">
                          QR Code for the event
                        </h5>
                        <img
                          src={IMAGE_URL + `${rsvpDetails?.qrCode}`}
                          className="mt-3 w-[25vh] h-[25vh] rounded-md object-cover mx-auto"
                          alt="QR Code"
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden bg-white border border-authBorder rounded-md overflow-y-auto overflow-x-hidden hide-scrollbar">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 h-full">
              <div className="col-span-3 h-full order-2 xl:order-1 lg:order-1">
                <div className="border-r border-authBorder h-full">
                  <div className="p-5 xl:p-5 lg:p-5">
                    <div className="grid grid-cols-12 h-full gap-5 pb-3 ">
                      {/* SHIPPING INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3 ">
                        <h2 className="text-lg font-ibm-bold text-black">
                          Company Information
                        </h2>
                        <h3 className="text-base font-ibm-regular text-greyText border-b border-authBorder pb-2">
                          {rsvpList
                            ?.filter((rsvp) => rsvp._id === id)
                            .map((rsvp, index) => (
                              <div key={index}>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Company Name:{" "}
                                  {rsvp?.companyDetails?.companyId?.companyId}
                                </p>
                              </div>
                            ))}
                          Area Covered By Brand :{" "}
                          {rsvpDetails?.companyDetails?.areaCoveredByBrand}
                          <br />
                          Billing Name :{" "}
                          {rsvpDetails?.companyDetails?.billingName}
                        </h3>

                        <p className="text-base font-ibm-bold text-black mt-3">
                          {rsvpDetails?.companyDetails?.name}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.companyDetails?.address?.address}
                        </p>

                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.companyDetails?.address?.city} ,{" "}
                          {rsvpDetails?.companyDetails?.address?.state} ,{" "}
                          {rsvpDetails?.companyDetails?.address?.zipCode}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.companyDetails?.address?.country}
                        </p>

                        {rsvpDetails?.companyDetails?.services?.length > 0 ? (
                          rsvpDetails.companyDetails.services.map(
                            (service, index) => (
                              <div key={index} className="mb-4">
                                <p className="text-base font-ibm-bold text-black mt-3">
                                  Services : {service.name}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText">
                                  {service.description}
                                </p>
                              </div>
                            )
                          )
                        ) : (
                          <p className="text-base font-ibm-regular text-greyText">
                            No services available
                          </p>
                        )}

                        <div>
                          <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2 mt-5">
                            Sponsors Details
                          </h3>

                          {rsvpList
                            ?.filter((rsvp) => rsvp._id === id) // Filter by ID from URL
                            .map((rsvp, index) => (
                              <div key={index}>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Primary Objective:{" "}
                                  {rsvp?.sponsorDetails?.primaryObjective}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Specific Requirement For Brand :{" "}
                                  {
                                    rsvp?.sponsorDetails
                                      ?.specificRequirementForBrand
                                  }
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Give Away To Attendees:{" "}
                                  {rsvp?.sponsorDetails?.giveAwayToAttendees}
                                </p>
                                <br />

                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Company Id:{" "}
                                  {rsvp?.sponsorDetails?.companyId?.companyId}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Company Name:{" "}
                                  {rsvp?.sponsorDetails?.companyId?.name}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Address:{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId?.address
                                      ?.address
                                  }
                                  ,{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId?.address
                                      ?.city
                                  }
                                  ,{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId?.address
                                      ?.state
                                  }
                                  ,{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId?.address
                                      ?.zipCode
                                  }
                                  ,{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId?.address
                                      ?.country
                                  }
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Website:{" "}
                                  <a
                                    href={
                                      rsvp?.sponsorDetails?.companyId
                                        ?.websiteUrl
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {
                                      rsvp?.sponsorDetails?.companyId
                                        ?.websiteUrl
                                    }
                                  </a>
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Sectors:{" "}
                                  {rsvp?.sponsorDetails?.companyId?.sectors?.join(
                                    ", "
                                  )}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Payment Status:{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId
                                      ?.paymentStatus
                                  }
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Billing Name:{" "}
                                  {rsvp?.sponsorDetails?.companyId?.billingName}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Area Covered By Brand:{" "}
                                  {
                                    rsvp?.sponsorDetails?.companyId
                                      ?.areaCoveredByBrand
                                  }
                                </p>

                                <br />
                                <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2 mt-5">
                                  Sponsorship Level
                                </h3>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  {rsvp?.sponsorDetails?.sponsorShipLevel?.name}
                                  {console.log(
                                    "%c Line:270 🍉 rsvp?.sponsorShipLevel?.name",
                                    "color:#2eafb0",
                                    rsvp?.sponsorShipLevel
                                  )}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* BILLING INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3">
                        <h2 className="text-xl font-ibm-bold text-black">
                          Personal Information
                        </h2>
                        <h3 className="text-base font-ibm-regular text-greyText border-b border-authBorder pb-2">
                          Working as : {rsvpDetails?.personalDetails?.workingAs}
                          <br />
                          Designation :{" "}
                          {rsvpDetails?.personalDetails?.designation}
                          <br />
                          Qualification :{" "}
                          {
                            rsvpDetails?.personalDetails
                              ?.educationalQualification
                          }
                        </h3>

                        <p className="text-base font-ibm-regular text-greyText mt-3">
                          {`${rsvpDetails?.personalDetails?.firstName} ${rsvpDetails?.personalDetails?.lastName}`}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.personalDetails?.email}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          Phone no : {rsvpDetails?.personalDetails?.phoneNumber}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          WhatsApp no :{" "}
                          {rsvpDetails?.personalDetails?.whatsAppPhoneNumber}
                        </p>
                        <br />
                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.personalDetails?.address?.address}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.personalDetails?.address?.city} ,{" "}
                          {rsvpDetails?.personalDetails?.address?.state} ,{" "}
                          {rsvpDetails?.personalDetails?.address?.zipCode}
                        </p>
                        <p className="text-base font-ibm-regular text-greyText">
                          {rsvpDetails?.personalDetails?.address?.country}
                        </p>

                        <div className="col-span-12 lg:col-span-6 px-3 py-3">
                          <h3 className="text-xl font-ibm-bold text-black border-b border-authBorder pb-2 mt-5">
                            Event Details
                          </h3>
                          {rsvpList
                            ?.filter((rsvp) => rsvp._id === id)
                            .map((rsvp, index) => (
                              <div key={index}>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Event Name:{" "}
                                  {rsvp?.sponsorDetails?.eventId?.name}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Description:{" "}
                                  {rsvp?.sponsorDetails?.eventId?.description}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Date:{" "}
                                  {rsvp?.sponsorDetails?.eventId?.eventDate}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Time:{" "}
                                  {rsvp?.sponsorDetails?.eventId?.eventTime}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Venue:{" "}
                                  {rsvp?.sponsorDetails?.eventId?.eventVenue}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Timeline:
                                </p>
                                {rsvp?.sponsorDetails?.eventId?.eventTimeLine?.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className="text-base font-ibm-regular text-greyText mt-2"
                                    >
                                      <strong>{item.name}</strong>:{" "}
                                      {item.description} at {item.location} on{" "}
                                      {item.date}
                                    </div>
                                  )
                                )}
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Specific Requirement for Brand:{" "}
                                  {
                                    rsvp?.sponsorDetails
                                      ?.specificRequirementForBrand
                                  }
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Interested In Promotional Items:{" "}
                                  {rsvp?.interestedInPromotionalItems
                                    ? "Yes"
                                    : "No"}
                                </p>
                                <p className="text-base font-ibm-regular text-greyText mt-2">
                                  Like To Feature In Marketing Materials:{" "}
                                  {rsvp?.likeToFeatureInMarketingMaterials
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1 h-full order-1 xl:order-2 lg:order-2">
                <div className="p-5 xl:p-5 lg:p-5">
                  <div className="grid grid-cols-6 border-b border-authBorder px-0 py-5 xl:px-2 lg:px-2">
                    <div className="col-span-4">
                      <h2 className="text-xl font-ibm-bold text-black">
                        {rsvpList
                          ?.filter((rsvp) => rsvp._id === id)
                          .map((rsvp, index) => (
                            <div key={index}>
                              <p className="text-base font-ibm-regular text-greyText mt-2">
                                Event ID :{" "}
                                {rsvp?.sponsorDetails?.eventId?.eventId}
                              </p>
                            </div>
                          ))}
                      </h2>
                      <h3 className=" text-sm font-ibm-regular text-greyText  pb-2">
                        {moment(rsvpDetails?.createdAt).format(
                          "MMM DD, YYYY HH:MM A"
                        )}
                      </h3>
                      <div className="col-span-2 mt-3">
                        <span class="bg-green-100  text-green-800 text-sm font-medium me-2 px-2.5 py-2 rounded dark:bg-green-900 dark:text-green-300">
                          {rsvpDetails?.paymentStatus}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="bg-greyBg rounded-md w-full py-2 px-5">
                      {rsvpDetails?.qrCode ? (
                        <img
                          src={IMAGE_URL + rsvpDetails.qrCode}
                          alt="QR Code"
                          className="w-full h-auto object-cover rounded-md"
                        />
                      ) : (
                        <p className="text-sm font-ibm-semibold text-greyText">
                          No QR code available
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RsvpDetails;
