const Input = (props) => {
  return (
    <input
      type={props.type}
      placeholder={props.placeholder}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onClick={props?.onClick}
      min={props?.min}
      max={props?.max}
      disabled={props?.disabled}
      className={`block w-full px-5 py-3 rounded-md border ${
        props?.error ? "border-red-500" : "border-authBorder"
      } text-sm font-ibm-regular text-black placeholder:text-sm placeholder:text-gray-500 placeholder:font-ibm-regular focus:outline-logoYellow`}
    />
  );
};

export default Input;
